import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/use-auth';
import axios from 'axios';

const MainPage = () => {

    const {isAuth, email} = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuth) {
          navigate('/login');
        }
      });

      const [razd, setRazd] = useState("");

      const addRazdel = () => {
        axios
        .post("https://admin.dropclick.pro/foradminkaaddanddellinks/addRaz.php", {
          name: razd
        })
        .then(res => {
            alert(res.data);
        })
      }
      const delRazdel = () => {
        axios
        .post("https://admin.dropclick.pro/foradminkaaddanddellinks/delRaz.php", {
          name: razd
        })
        .then(res => {
            alert(res.data);
        })
      }

      const [adress, setAdress] = useState("");
      const [perev, setPerev] = useState("");

      const addPay = () => {
        axios
        .post("https://admin.dropclick.pro/foradminkaaddanddellinks/addPay.php", {
            adress: adress,
            perev: perev
        })
        .then(res => {
            alert(res.data);
        })
      }
      const delPay = () => {
        axios
        .post("https://admin.dropclick.pro/foradminkaaddanddellinks/delPay.php", {
            adress: adress
        })
        .then(res => {
            alert(res.data);
        })
      }

      const [wallet, setWallet] = useState("");
      const [count, setCount] = useState("");

      const addWallets = () => {
        axios
        .post("https://admin.dropclick.pro/foradminkaaddanddellinks/addWallets.php", {
            email: wallet,
            price: count
        })
        .then(res => {
            alert(res.data);
        })
      }

      const [sub, setSub] = useState("");
      const [nach, setNach] = useState("");
      const [kon, setKon] = useState("");

      const addSub = () => {
        axios
        .post("https://admin.dropclick.pro/foradminkaaddanddellinks/addSub.php", {
            email: sub,
            nach: nach,
            date: kon
        })
        .then(res => {
            alert(res.data);
        })
      }

      const [raz, setRaz] = useState("");
      const [title, setTitle] = useState("");
      const [description, setDescription] = useState("");
      const [time, setTime] = useState("");
      const [money, setMoney] = useState("");
      const [img, setImg] = useState("");
      const [invest, setInvest] = useState("");
      const [tw, setTw] = useState("");


      const addCard = () => {
        axios
        .post("https://admin.dropclick.pro/foradminkaaddanddellinks/addCard.php", {
            raz: raz,
            title: title,
            description: description,
            time: time,
            money: money,
            img: img,
            invest: invest,
            tw: tw
        })
        .then(res => {
            alert(res.data);
        })
      }

      const [im, setIm] = useState("");

      const delCard = () => {
        axios
        .post("https://admin.dropclick.pro/foradminkaaddanddellinks/delCard.php", {
            name: im
        })
        .then(res => {
            alert(res.data);
        })
      }

      const [name, setName] = useState("");
      const [date, setDate] = useState("");
      const [website, setWebsite] = useState("");
      const [twitter, setTwitter] = useState("");
      const [discord, setDiscord] = useState("");
      const [telegram, setTelegram] = useState("");
      const [descript, setDescript] = useState("");

      const addGuid = () => {
        axios
        .post("https://admin.dropclick.pro/foradminkaaddanddellinks/addGuid.php", {
            name: name,
            date: date,
            website: website,
            twitter: twitter,
            discord: discord,
            telegram: telegram,
            description: descript
        })
        .then(res => {
            alert(res.data);
        })
      }

      const [zag, setZag] = useState("");

      const delGuid = () => {
        axios
        .post("https://admin.dropclick.pro/foradminkaaddanddellinks/delGuid.php", {
            name: zag
        })
        .then(res => {
            alert(res.data);
        })
      }
      const [nameru, setNameru] = useState("");
      const [dateru, setDateru] = useState("");
      const [websiteru, setWebsiteru] = useState("");
      const [twitterru, setTwitterru] = useState("");
      const [discordru, setDiscordru] = useState("");
      const [telegramru, setTelegramru] = useState("");
      const [descriptru, setDescriptru] = useState("");

      const addGuidRU = () => {
        axios
        .post("https://admin.dropclick.pro/foradminkaaddanddellinks/addGuidRU.php", {
            name: nameru,
            date: dateru,
            website: websiteru,
            twitter: twitterru,
            discord: discordru,
            telegram: telegramru,
            description: descriptru
        })
        .then(res => {
            alert(res.data);
        })
      }

      const [zagru, setZagru] = useState("");

      const delGuidRU = () => {
        axios
        .post("https://admin.dropclick.pro/foradminkaaddanddellinks/delGuidRU.php", {
            name: zagru
        })
        .then(res => {
            alert(res.data);
        })
      }

      const [nazv, setNazv] = useState("");
      const [titulnik, setTitulnik] = useState("");
      const [instr, setInstr] = useState("");

      const addInstr = () => {
        axios
        .post("https://admin.dropclick.pro/foradminkaaddanddellinks/addInstr.php", {
            name: nazv,
            title: titulnik,
            instr: instr
        })
        .then(res => {
            alert(res.data);
        })
      }

      
      const [ima, setIma] = useState("");
      const [tit, setTit] = useState("");

      const delInstr = () => {
        axios
        .post("https://admin.dropclick.pro/foradminkaaddanddellinks/delInstr.php", {
            name: ima,
            title: tit
        })
        .then(res => {
            alert(res.data);
        })
      }

      const [nazvru, setNazvru] = useState("");
      const [titulnikru, setTitulnikru] = useState("");
      const [instrru, setInstrru] = useState("");

      const addInstrRU = () => {
        axios
        .post("https://admin.dropclick.pro/foradminkaaddanddellinks/addInstrRU.php", {
            name: nazvru,
            title: titulnikru,
            instr: instrru
        })
        .then(res => {
            alert(res.data);
        })
      }

      
      const [imaru, setImaru] = useState("");
      const [titru, setTitru] = useState("");

      const delInstrRU = () => {
        axios
        .post("https://admin.dropclick.pro/foradminkaaddanddellinks/delInstrRU.php", {
            name: imaru,
            title: titru
        })
        .then(res => {
            alert(res.data);
        })
      }

      const [naz, setNaz] = useState("");
      const [fire, setFire] = useState(false);
      const [whath, setWhath] = useState(false);
      const [stong, setStong] = useState(false);

      function chengeFire() {
        setFire(!fire);
     }
      function chengeWhath() {
        setWhath(!whath);
     }
      function chengeStong() {
        setStong(!stong);
     }

     const addIcon = () => {
      axios
      .post("https://admin.dropclick.pro/foradminkaaddanddellinks/addIcon.php", {
          title: naz,
          fire: fire,
          whath: whath,
          stong: stong
      })
      .then(res => {
          alert(res.data);
      })
    }

  return (
    <div className='container' style={{textAlign: 'center'}}>
    <div>
        <h1 style={{color: 'white'}}>Управление разделами</h1>
        <div style={{backgroundColor: "aquamarine", borderRadius: "15px", padding: "30px"}}>
                <h1>Добавить/Удалить раздел</h1>
                <input className='mb-3' type="text" onChange={(e) => setRazd(e.target.value)} name="name" placeholder="Название"/> <br />
                <button className='btn btn-primary' onClick={addRazdel}>Добавить</button>
                <button className='btn btn-danger' style={{marginLeft: '15px'}} onClick={delRazdel}>Удалить</button>
        </div>
    </div>
    <br/>
    <div>
        <h1 style={{color: 'white'}}>Управление данными оплаты</h1>
        <div style={{backgroundColor: "gold", borderRadius: "15px", padding: "30px"}}>
                <h1>Добавить/Удалить данные для оплаты</h1>
                <input className='mb-2' type="text" name="adress" placeholder="Адрес кошелька" onChange={(e) => setAdress(e.target.value)}/> <br />
                <input type="text" name="perev" placeholder="Сеть перевода" onChange={(e) => setPerev(e.target.value)}/> <br />
                <button className='btn btn-primary mt-3' onClick={addPay}>Добавить</button>
                <button className='btn btn-danger mt-3' style={{marginLeft: '15px'}} onClick={delPay}>Удалить</button>
        </div>
    </div>
    <div>
        <h1 style={{color: 'white'}}>Управление юзерами</h1>
        <div style={{backgroundColor: "blanchedalmond", borderRadius: "15px", padding: "30px"}}>
                <h1>Добавить/Обновить кол-во денег в кошельке</h1>
                <input className='mb-2' type="text" name="email" placeholder="Email" onChange={(e) => setWallet(e.target.value)} /> <br />
                <input type="text" name="count" placeholder="Сумма (просто числом)" onChange={(e) => setCount(e.target.value)} /> <br />
                <button className='btn btn-primary mt-3' onClick={addWallets}>Добавить/Обновить</button>

                <h1 className='mt-5'>Добавить/Обновить подписку</h1>
                <input className='mb-2' type="text" name="email" placeholder="Email" onChange={(e) => setSub(e.target.value)}/> <br />
                <p style={{margin: '0'}}>Дата начала подписки:</p>
                <input className='mb-2' type="date" id="nach" name="nach" onChange={(e) => setNach(e.target.value)}/> <br />
                <p style={{margin: '0'}}>Дата окончания подписки:</p>
                <input type="date" id="date" name="date" onChange={(e) => setKon(e.target.value)}/> <br />
                <button className='btn btn-primary mt-3' onClick={addSub}>Добавить/Обновить</button>
        </div>
    </div>
    <div>
        <h1 style={{color: 'white'}}>Управление карточками гайдов</h1>
        <div style={{backgroundColor: "darkcyan", borderRadius: "15px", padding: "30px"}}>
                <h1>Добавить/Обновить карточку</h1>
                <input className='mb-2' type="text" name="raz" placeholder="Раздел" onChange={(e) => setRaz(e.target.value)} /> <br />
                <input className='mb-2' type="text" name="title" placeholder="Заголовок" onChange={(e) => setTitle(e.target.value)} /> <br />
                <input className='mb-2' type="text" name="description" placeholder="Описание" onChange={(e) => setDescription(e.target.value)} /> <br />
                <input className='mb-2' type="text" name="time" placeholder="Время" onChange={(e) => setTime(e.target.value)} /> <br />
                <input className='mb-2' type="text" name="money" placeholder="Цена" onChange={(e) => setMoney(e.target.value)} /> <br />
                <input className='mb-2' type="text" name="img" placeholder="Ссылка на картинку" onChange={(e) => setImg(e.target.value)} /> <br />
                <input className='mb-2' type="text" name="invest" placeholder="Инвестиции" onChange={(e) => setInvest(e.target.value)} /> <br />
                <input type="text" name="tw" placeholder="Твитер скор" onChange={(e) => setTw(e.target.value)} /> <br />
                <button className='btn btn-primary mt-3' onClick={addCard}>Добавить/Обновить</button>

                <h1 className='mt-3'>Добавить/убрать значки</h1>
                <input type="text" name='title' id='title' placeholder='Название карточки' onChange={(e) => setNaz(e.target.value)} /> <br />
                <div className="btn-group mt-3" role="group" aria-label="Basic checkbox toggle button group">
                  <input type="checkbox" className="btn-check" id="fire" name='fire' autocomplete="off" checked={fire} onChange={chengeFire} />
                  <label className="btn btn-outline-success" htmlFor="fire">Огонь</label>

                  <input type="checkbox" className="btn-check" id="whath" name='whath' autocomplete="off" checked={whath} onChange={chengeWhath} />
                  <label className="btn btn-outline-success" htmlFor="whath">Часы</label>

                  <input type="checkbox" className="btn-check" id="stong" name='stong' autocomplete="off" checked={stong} onChange={chengeStong} />
                  <label className="btn btn-outline-success" htmlFor="stong">Мешок со стрелками</label>
                </div> <br />
                <button className='btn btn-primary mt-3' onClick={addIcon}>Обновить значения</button>

                <h1 className='mt-4'>Удалить карточку</h1>
                <input type="text" name="name" placeholder="Заголовок" onChange={(e) => setIm(e.target.value)} /> <br />
                <button className='btn btn-danger mt-3' onClick={delCard}>Удалить</button>
        </div>
    </div>
    <div>
        <h1 style={{color: 'white'}}>Управление шапками гайда</h1>
        <div style={{backgroundColor: "sandybrown", borderRadius: "15px", padding: "30px"}}>
                <h1>Добавить/Обновить шапку гайда (на английском)</h1>
                <input className='mb-2' type="text" name="name" placeholder="Название" onChange={(e) => setName(e.target.value)}/> <br />
                <input className='mb-2' type="text" name="date" placeholder="Дата" onChange={(e) => setDate(e.target.value)}/> <br />
                <input className='mb-2' type="text" name="website" placeholder="сайт" onChange={(e) => setWebsite(e.target.value)}/> <br />
                <input className='mb-2' type="text" name="twitter" placeholder="твитер" onChange={(e) => setTwitter(e.target.value)}/> <br />
                <input className='mb-2' type="text" name="discord" placeholder="дискорд" onChange={(e) => setDiscord(e.target.value)}/> <br />
                <input className='mb-2' type="text" name="telegram" placeholder="телеграм" onChange={(e) => setTelegram(e.target.value)}/> <br />
                <textarea name="description" id="description" cols="30" rows="10" onChange={(e) => setDescript(e.target.value)}></textarea> <br />
                <button className='btn btn-primary mt-3' onClick={addGuid}>Добавить/Обновить</button>


                <h1 className='mt-4'>Удалить шапку гайда (на английском)</h1>
                <input type="text" name="name" placeholder="Название" onChange={(e) => setZag(e.target.value)} /> <br />
                <button className='btn btn-danger mt-3' onClick={delGuid}>Удалить</button>
        </div>
        <div style={{backgroundColor: "darkgray", borderRadius: "15px", padding: "30px"}}>
                <h1>Добавить/Обновить шапку гайда (на русском)</h1>
                <input className='mb-2' type="text" name="name" placeholder="Название" onChange={(e) => setNameru(e.target.value)}/> <br />
                <input className='mb-2' type="text" name="date" placeholder="Дата" onChange={(e) => setDateru(e.target.value)}/> <br />
                <input className='mb-2' type="text" name="website" placeholder="сайт" onChange={(e) => setWebsiteru(e.target.value)}/> <br />
                <input className='mb-2' type="text" name="twitter" placeholder="твитер" onChange={(e) => setTwitterru(e.target.value)}/> <br />
                <input className='mb-2' type="text" name="discord" placeholder="дискорд" onChange={(e) => setDiscordru(e.target.value)}/> <br />
                <input className='mb-2' type="text" name="telegram" placeholder="телеграм" onChange={(e) => setTelegramru(e.target.value)}/> <br />
                <textarea name="description" id="description" cols="30" rows="10" onChange={(e) => setDescriptru(e.target.value)}></textarea> <br />
                <button className='btn btn-primary mt-3' onClick={addGuidRU}>Добавить/Обновить</button>
                <h1 className='mt-4'>Удалить шапку гайда (на русском)</h1>
                <input type="text" name="name" placeholder="Название" onChange={(e) => setZagru(e.target.value)} /> <br />
                <button className='btn btn-danger mt-3' onClick={delGuidRU}>Удалить</button>
        </div>
    </div>
    <div>
        <h1 style={{color: 'white'}}>Управление гайдами</h1>
        <div style={{backgroundColor: "lightblue", borderRadius: "15px", padding: "30px"}}>
                <h1>Добавить/Обновить гайд (на английском)</h1>
                <input className='mb-2' type="text" name="name" placeholder="Название всего гайда" onChange={(e) => setNazv(e.target.value)} /> <br />
                <input className='mb-2' type="text" name="title" placeholder="Титульник" onChange={(e) => setTitulnik(e.target.value)} /> <br />
                <textarea name="instr" id="" cols="30" placeholder="Инструкция" rows="10" onChange={(e) => setInstr(e.target.value)}></textarea> <br />
                <button className='btn btn-primary mt-3' onClick={addInstr}>Добавить/Обновить</button>


                <h1 className='mt-4'>Удалить гайд (на английском)</h1>
                <input className='mb-2' type="text" name="name" placeholder="Название всего гайда" onChange={(e) => setIma(e.target.value)} /> <br />
                <input type="text" name="title" placeholder="Титульник" onChange={(e) => setTit(e.target.value)} /> <br />
                <button className='btn btn-danger mt-3' onClick={delInstr}>Удалить</button>
        </div>
        <div style={{backgroundColor: "mediumseagreen", borderRadius: "15px", padding: "30px"}}>
                <h1>Добавить гайд (на русском)</h1>
                <input className='mb-2' type="text" name="name" placeholder="Название всего гайда" onChange={(e) => setNazvru(e.target.value)} /> <br />
                <input className='mb-2' type="text" name="title" placeholder="Титульник" onChange={(e) => setTitulnikru(e.target.value)} /> <br />
                <textarea name="instr" id="" cols="30" placeholder="Инструкция" rows="10" onChange={(e) => setInstrru(e.target.value)}></textarea> <br />
                <button className='btn btn-primary mt-3' onClick={addInstrRU}>Добавить/Обновить</button>


                <h1 className='mt-4'>Удалить гайд (на русском)</h1>
                <input className='mb-2' type="text" name="name" placeholder="Название всего гайда" onChange={(e) => setImaru(e.target.value)} /> <br />
                <input type="text" name="title" placeholder="Титульник" onChange={(e) => setTitru(e.target.value)} /> <br />
                <button className='btn btn-danger mt-3' onClick={delInstrRU}>Удалить</button>
        </div>
    </div>
    </div>
  )
}

export default MainPage